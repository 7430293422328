import { createContext, useContext, useState } from "react";

const languageContext = createContext();

export default languageContext;

export const useLanguageContext = () => useContext(languageContext);

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(
    localStorage.getItem("@language") ? localStorage.getItem("@language") : "EN"
  );

  const switchLanguage = () => {
    setLanguage((prev) => {
      localStorage.setItem("@language", prev === "EN" ? "AR" : "EN");
      return prev === "EN" ? "AR" : "EN";
    });
  };

  return (
    <languageContext.Provider value={[language, switchLanguage]}>
      <div dir={language === "EN" ? "ltr" : "rtl"}>{children}</div>
    </languageContext.Provider>
  );
};
