import React from "react";

const GoogleUser = React.lazy(() => import("../Components/googleUser"));
const HomePage = React.lazy(() => import("../Pages/Home.page"));
const AboutPage = React.lazy(() => import("../Pages/About.page"));
const ContactUsPage = React.lazy(() => import("../Pages/ContactUs.page"));
const SignupPage = React.lazy(() => import("../Pages/Signup.page"));
const LoginPage = React.lazy(() => import("../Pages/Login.page"));
const ForgotPasswordPage = React.lazy(() => import("../Pages/ForgotPassword.page"));
const NewPasswordPage = React.lazy(() => import("../Pages/NewPassword.page"));
const CategoriesPage = React.lazy(() => import("../Pages/Categories.page"));
const NotFoundPage = React.lazy(() => import("../Pages/NotFound"));
const SingleCategoriesPage = React.lazy(() =>
  import("../Pages/SingleCategory.page")
);
const ContractBuilderPage = React.lazy(() =>
  import("../Pages/ContractBuilder.page")
);
const PaymentErrorPage = React.lazy(() => import("../Pages/PaymentError.page"));
const AccountVerificationPage = React.lazy(() =>
  import("../Pages/AccountVerification")
);
const PaymentVerificationPage = React.lazy(() =>
  import("../Pages/PaymentVerification.page")
);
const ProfilePage = React.lazy(() => import("../Pages/Profile.page"));
const CheckoutPage = React.lazy(() => import("../Pages/Checkout.page"));
const PaymentsPage = React.lazy(() => import("../Pages/Payment.page"));

const PrintAgrimentsPage = React.lazy(() =>
  import("../Pages/PrintAgreements.page")
);

export const routes = [
  {
    name: "home",
    path: "/",
    element: HomePage,
    private: false,
  },
  {
    name: "about",
    path: "/about-us",
    element: AboutPage,
    private: false,
  },
  {
    name: "contact",
    path: "/contact-us",
    element: ContactUsPage,
    private: false,
  },
  {
    name: "contact",
    path: "/profile",
    element: ProfilePage,
    private: true,
  },
  {
    name: "login",
    path: "/login",
    element: LoginPage,
    private: false,
  },
  {
    name: "signup",
    path: "/sign-up",
    element: SignupPage,
    private: false,
  },
  {
    name: "categories",
    path: "/categories",
    element: CategoriesPage,
    private: false,
  },
  {
    name: "category",
    path: "/category/:id",
    element: SingleCategoriesPage,
    private: false,
  },
  {
    name: "printAgreements",
    path: "/print-agreements",
    element: PrintAgrimentsPage,
    private: true,
  },
  {
    name: "notfound",
    path: "*",
    element: NotFoundPage,
    private: false,
  },
  {
    name: "forgot password",
    path: "/forgot-password",
    element: ForgotPasswordPage,
    private: false,
  },
  {
    name: "new password",
    path: "/new-password",
    element: NewPasswordPage,
    private: false,
  },
  {
    name: "account verification",
    path: "/account-verification",
    element: AccountVerificationPage,
    private: false,
  },
  {
    name: "payment error",
    path: "/payment-error",
    element: PaymentErrorPage,
    private: true,
  },
  {
    name: "payment verification",
    path: "/verify-payment",
    element: PaymentVerificationPage,
    private: true,
  },
  {
    name: "contract builder",
    path: "/contract-builder/:id/:cid/:paymentId",
    element: ContractBuilderPage,
    private: true,
  },
  {
    name: "checkout",
    path: "/checkout/",
    element: CheckoutPage,
    private: true,
  },
  {
    name: "Payments",
    path: "/payment/",
    element: PaymentsPage,
    private: true,
  },
  {
    name: "Google-user",
    path: "/google-user/",
    element: GoogleUser,
    private: false,
  },
];
