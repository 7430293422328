import AuthAxios from './AuthAxios';

class AuthServices {
  signup = (data) => AuthAxios.post('signup', data);
  checkUser = (email) => AuthAxios.get(`checkUser/${email}`);
  forgotPassword = (data) => AuthAxios.post('forgot-password', data);
  getUserById = (id) => AuthAxios.get(`user/${id}`);
  login = (data) => AuthAxios.post('signin', data);;
  sendSupportMessage = (data) => AuthAxios.post(`contactus`, data);
  verifyToken = () => AuthAxios.get('login/success', { withCredentials: true });
  logout = () => AuthAxios.get('logout', { withCredentials: true });
}

export default new AuthServices();
