import { configureStore } from "@reduxjs/toolkit";
import snackbarSlice from "./Slices/snackbar.slice";
import cartSlice from "./Slices/cart.slice";
import userSlice from "./Slices/user.slice";

export const store = configureStore({
  reducer: {
    snackbar: snackbarSlice,
    cart: cartSlice,
    user: userSlice,
  },
});
