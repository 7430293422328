import { LinearProgress, Stack, Typography } from "@mui/material";
import React from "react";
// import { appDetails } from "../Utils/Details";
import transparentLogo from "../Assets/Logos/logo-nobackground.png";

const Loading = () => {
  return (
    <Stack alignItems={"center"} justifyContent="center" height={"100vh"}>
      <img
        src={process.env.PUBLIC_URL + transparentLogo}
        alt="logo"
        width={150}
      />
      <LinearProgress color="primary" sx={{ my: 2, width: 150 }} />
      <Typography>Please wait</Typography>
    </Stack>
  );
};

export default Loading;
