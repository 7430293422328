import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import { AuthProvider } from "./Context";
import { colors } from "./Utils/Colors";
import { BrowserRouter as Router } from "react-router-dom";
import CustomizedSnackbars from "./Components/Snackbar";
import { Suspense } from "react";
import Loading from "./Components/Loading";
import AlertProvider from "./Context/Alert.context";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: colors.primary,
      },
      secondary: {
        main: colors.secondary,
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CustomizedSnackbars />
      <Router>
        <Suspense fallback={<Loading />}>
          <AlertProvider>
            <AuthProvider />
          </AlertProvider>
        </Suspense>
      </Router>
      <TawkMessengerReact
        propertyId={process.env.REACT_APP_TAWK_PROPERTY_ID}
        widgetId={process.env.REACT_APP_TAWK_WIDGET_ID}
      />
    </ThemeProvider>
  );
}

export default App;
