/* eslint-disable react-hooks/exhaustive-deps */
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import LoadingSplash from '../Components/Loading';
import { setAxiosToken } from '../Utils/SettingToken';
import Navigator from '../Router';
import AuthService from '../Service/Auth.service';

const AuthContext = createContext();

export default AuthContext;
export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const sessionName = process.env.REACT_APP_SESSION_NAME;

  const initState = {
    user: null,
    isLoading: true,
    role: null,
  };

  const actions = {
    SET_USER: 'SET_USER',
    UPDATE_USER: 'UPDATE_USER',
    CLEAR_USER: 'CLEAR_USER',
  };

  const authReducer = (prevState, action) => {
    switch (action.type) {
      case actions.SET_USER:
        return {
          ...prevState,
          userToken: action.token,
          user: action.user,
          role: action.role,
          isLoading: false,
        };
      case actions.UPDATE_USER:
        return {
          ...prevState,
          user: action.user,
        };
      case actions.CLEAR_STATE:
        return {
          ...initState,
          isLoading: false,
        };
      default:
        return {
          ...initState,
          isLoading: false,
        };
    }
  };
  const [authState, dispatch] = useReducer(authReducer, initState);

  //   saving api token into Local session and API header
  const saveToken = (token, uuid, role) => {
    localStorage.setItem(sessionName, JSON.stringify({ token, uuid, role }));
    setAxiosToken(token);
  };
  const clearToken = () => {
    localStorage.removeItem(sessionName);
    setAxiosToken();
  };

  // ------------------ Based on the action change the auth STATE  ------------------
  const authActions = useMemo(
    () => ({
      // retrieving Token from the secure
      retrieveToken: async () => {
        const session = JSON.parse(localStorage.getItem(sessionName));
        if (session) {
          const { token, uuid } = session;
          setAxiosToken(token);
          AuthService.getUserById(uuid)
            .then(({ data }) => {
              dispatch({ type: actions.SET_USER, user: data });
            })
            .catch((e) => {
              dispatch({ type: actions.CLEAR_STATE });
            });
        } else {
          dispatch({ type: actions.CLEAR_STATE });
        }
      },
      login: (user, role) => {
        saveToken(user.token, user._id, role);
        dispatch({ type: actions.SET_USER, user, role });
      },
      logout: async () => {
        try {
          await AuthService.logout();
        } catch (e) {
        } finally {
          clearToken();
          dispatch({ type: actions.CLEAR_STATE });
        }
      },
      signup: (user, role) => {
        saveToken(user.token, user._id, role);
        dispatch({ type: actions.SET_USER, user, role });
      },

      updateUserProfile: (user) => {
        dispatch({
          type: actions.UPDATE_USER,
          user,
        });
      },
    }),
    []
  );

  useEffect(() => {
    authActions.retrieveToken();
  }, []);

  if (authState.isLoading) {
    return <LoadingSplash />;
  }
  return (
    <AuthContext.Provider value={[authState.user, authActions, authState.role]}>
      <Navigator />
    </AuthContext.Provider>
  );
};
