import axios from 'axios';

export const baseURL = () => {
  switch (process.env.NODE_ENV) {
    case 'development':
      return process.env.REACT_APP_API_URL;
    default:
      return process.env.REACT_APP_API_URL_PROD;
  }
};

const AuthAxios = axios.create({
  baseURL: baseURL() + 'api/',
  headers: {
    'Access-Control-Allow-Origin': [baseURL()],
  },
  // timeout: 5000,
  // timeoutErrorMessage: "Please check your connections",
});

export default AuthAxios;
