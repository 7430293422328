import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export const alertContext = React.createContext();

export default function AlertProvider({ children }) {
  const [open, setOpen] = React.useState(false);
  const [content, setContent] = React.useState({
    title: "Print Agreement",
    body: (
      <>
        <div style={{ fontWeight: 600 }}>
          Are you sure do you want to print this?{" "}
        </div>
        <p style={{ fontSize: 13 }}>
          Note: This action can't be undo. If you Print this agreement. you
          can't edit this again. So please check the contents before you print.
        </p>
      </>
    ),
    onClick: () => {},
  });

  const handleClose = () => {
    setOpen(false);
  };

  const alertBoxActions = React.useMemo(
    () => ({
      open: (onClick) => {
        setContent((prev) => ({
          ...prev,
          onClick,
        }));
        setOpen(true);
      },
    }),
    []
  );
  const handlePrint = async () => {
    await content.onClick();
    handleClose();
  };

  return (
    <alertContext.Provider value={[alertBoxActions]}>
      {children}
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {content.title || "Alert"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content.body}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handlePrint}>Print now</Button>
        </DialogActions>
      </Dialog>
    </alertContext.Provider>
  );
}
