import { createSlice } from "@reduxjs/toolkit";

const initialState = { resetUser: { email: "", reset: false } };

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetingUser: (state, action) => {
      state.resetUser = action.payload;
    },
    reInitializeUser: (state, action) => {
      state.resetUser = { email: "", reset: false };
    },
  },
});

// Action creators are generated for each case reducer function
export const { resetingUser, reInitializeUser } = userSlice.actions;

export default userSlice.reducer;
