import { createSlice } from "@reduxjs/toolkit";

const initialState = { value: { agr: [], totalQty: 0 } };

export const cartSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const filteredItem = state.value.agr.filter(
        (ele) => ele._id === action.payload.agr._id
      );

      if (filteredItem.length > 0) {
        const mergedItem = {
          ...action.payload.agr,
          qty: parseInt(filteredItem[0].qty) + parseInt(action.payload.agr.qty),
        };

        state.value = {
          agr: [
            ...state.value.agr.filter(
              (ele) => ele._id !== action.payload.agr._id
            ),
            mergedItem,
          ],
          totalQty: action.payload.totalQty,
        };
      } else {
        state.value = {
          agr: [...state.value.agr, action.payload.agr],
          totalQty: action.payload.totalQty,
        };
      }
    },
    addToCartWithMembership: (state, action) => {
      state.value = {
        agr: [...state.value.agr, ...action.payload.agr],
        totalQty: action.payload.totalQty,
      };
    },
    updateCartRedux: (state, action) => {
      state.value = {
        agr: action.payload.agr,
        totalQty: action.payload.totalQty,
      };
    },
    removeFromCart: (state, action) => {
      const filteredItem = state.value.agr.find(
        (ele) => ele.id === action.payload
      );
      state.value = {
        agr: state.value.agr.filter((ele) => ele.id !== action.payload),
        totalQty: state?.value.totalQty - filteredItem?.qty,
      };
    },
    reInitialCard: (state) => {
      state.value = { agr: [], totalQty: 0 };
    },
    reAddCart: (state, action) => {
      let qty;
      action.payload.map((actions) => (qty = actions.qty));
      state.value = { agr: action.payload, totalQty: qty };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addToCart,
  removeFromCart,
  reInitialCard,
  addToCartWithMembership,
  updateCartRedux,
  reAddCart,
} = cartSlice.actions;

export default cartSlice.reducer;
